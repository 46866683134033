<script>
import { OptionsComponent, navigateKey } from '@drapejs/core';

import LinkBox from "../components/LinkBox.vue";
import useMeta from "@/composables/useMeta";

export const allCategoryValue = "all";
export const otherCategoryValue = "other";

export default {
  extends: OptionsComponent,
  components: {
    LinkBox,
  },
  data: () => ({
    categoryId: allCategoryValue,
  }),
  inject: {
    navigate: navigateKey,
  },
  computed: {
    heading() {
      return this.$page?.fields?.Heading;
    },
    subTitle() {
      return this.$page?.fields?.SubTitle;
    },
    categories() {
      const categories = [...(this.$page?.dataJson?.categories || [])];
      categories.unshift({
        value: allCategoryValue,
        text: this.$globalTexts.blog__all_category,
      });

      if (
        this.articles.length > 0 &&
        this.articles.find((a) => a.categories.length === 0)
      ) {
        categories.push({
          value: otherCategoryValue,
          text: this.$globalTexts.blog__other_category,
        });
      }

      return categories;
    },
    filteredArticles() {
      const articles = this.articles
        .filter((f) => f)
        .map((c) => {
          let obj = { ...c };
          obj.image = {
            id: c.imageId,
            dimension: {},
          };
          obj.name = c.title;
          obj.linkSubtitle = c.subTitle;
          return obj;
        });

      if (this.categoryId === allCategoryValue) return articles;
      if (this.categoryId === otherCategoryValue)
        return articles.filter((a) => a.categories.length === 0);

      return articles.filter((a) =>
        a.categories.find((c) => c === this.categoryId)
      );
    },
    articles() {
      return [...(this.$page?.dataJson?.articles || [])];
    },
    blocks() {
      return this.$page?.blocks;
    },
  },
  methods: {
    async markCategoryAsActive(category) {
      await this.navigate(this.$route.pathname + "?category=" + category.value);
      this.categoryId = category.value;
    },
  },
  mounted() {
    if (
      this.$route?.query?.category &&
      this.categories.find((c) => c.value === this.$route.query.category)
    ) {
      this.categoryId = this.$route.query.category;
    } else {
      this.categoryId = allCategoryValue;
    }
  },
  created() {
    useMeta();
  },
};
</script>

<template>
  <div class="blog-index">
    <blocks-repeater v-if="blocks" :blocks="blocks.Top" />
    <h3 class="blog-index__heading">
      {{ heading }}
    </h3>
    <div class="blog-index__subtitle" v-if="subTitle">{{ subTitle }}</div>
    <div class="blog-index__categories">
      <div
        v-for="category in categories"
        :key="category.value"
        class="blog-index__category"
        :class="{
          'blog-index__category--active': category.value === categoryId,
        }"
        @click="markCategoryAsActive(category)"
      >
        {{ category.text }}
      </div>
    </div>
    <div class="blog-index__articles">
      <link-box
        v-for="article in filteredArticles"
        :key="article.url"
        :link="article"
        class="blog-index__article"
      />
    </div>

    <blocks-repeater v-if="blocks" :blocks="blocks.Main" />
  </div>
</template>

<style>
.blog-index {
  max-width: calc(var(--header-max-width) + 20px);
  margin: auto;
  padding: 20px 0;
  overflow: hidden;
}

.blog-index__heading {
  font-size: 24px;
  color: black;
  font-weight: 700;
  text-align: center;
}

.blog-index__subtitle {
  font-size: 16px;
  color: black;
  text-align: center;
  max-width: 77%;
  margin: 0 auto;
  padding-bottom: 25px;
}

.blog-index__categories {
  justify-content: center;
  display: flex;
}

.blog-index__category {
  margin: 2.5rem 0.75rem 0;
  font-size: 14px;
  color: #797b80;
  cursor: pointer;
  padding-bottom: 0.35rem;
  user-select: none;
}

.blog-index__category--active {
  color: #1a1a1a;
  border-bottom: 1px solid #1a1a1a;
}

.blog-index__articles {
  padding: 1.5rem 10px 0;
}

.blog-index__article {
  margin-bottom: 1rem;
}

@media (--tabletAndDesktop) {
  .blog-index__articles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-template-rows: auto;
    gap: 40px 30px;
    padding: 2.5rem 10px 0;
  }
}
</style>
